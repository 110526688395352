.navigation {
    width: 100%;
    height: 4rem;
    background-color: rgba(84,8,107,1); 
    display: flex;
    justify-content: space-between;
    align-items: center;
    position:fixed;  /*keep nav on top*/
    top:0;
    z-index: 30;
  }
  

  .logo {
    font-family: "Lato", sans-serif;
    font-size: 2rem;
    color: white;
    margin: 0;
  }

  .navigation-menu ul {
    display:flex;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
  }
  
  .navigation-menu li {
    margin: 0 1rem;
    white-space: nowrap;
  }
  
  
  .navigation-menu a:not(.button) {
    text-decoration: none;
    color: white;
    font-weight: bold;
    /*this padding below is a  hacky way of keeping nav links horizontally stable when hover effect applied*/
    padding-left: 9px; 
    padding-right: 9px;
 
  }
  

  .navigation-menu a:not(.button):hover {
    color: var(--create-color);
    background-color: white;
    padding:.5rem; 
    outline: 2px solid var(--create-color);
    outline-offset: -3px; 
    border:  solid white 1px;
    border-radius: 5px;
  }   
  
  .navigation-menu a.active {
    /*color: rgba(5,8,51,1);    /* #95bcf0;  */
    padding-bottom: 0.25rem;
    padding-top:0.25rem;
    border-bottom: 2px solid white;
    border-radius: 5px;
  }  
  
  .hamburger-btn {
    display:none;
    width: 3rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    vertical-align: middle;
  }
  
  
  .hamburger-btn-line {
    width: 100%;
    height: 0.2rem;
    background: white;
    display: block;
    margin: 0.6rem 0;
    border-radius: 5px;
  }
  
  
  @media(max-width:72rem) {

    .navigation {
        justify-content: flex-start;
    }

    .hamburger-btn {
      display:block;
      margin-left: 2rem;
    
    }
    
  .navigation-menu ul {
    flex-direction: column;
    position: fixed;
    top: 4rem;
    left: 0;
    width: 100%;
    min-height: 18.5rem;
    background-color: rgba(84,8,107,1); ;
    border-top: 1px solid rgba(5,8,51,1);
    display: none;
  }
  .navigation-menu.expanded ul {
    display: block;
  }

  .navigation-menu li {
    text-align: center;
    margin: 1rem;

  }

}






