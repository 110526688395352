.search-container {
    position: relative;
}

.search-filters-container {
    position: absolute;
    top: 42px;
    min-height: 100px;
    border: 1px solid #000;
    width: 100%;
    border-radius: 5px;
    box-shadow: #aaa 2px 5px 7px;
    background-color: #fff;
    z-index: 10;
}

.search-filters-container.locked-open {
    position: initial;
    box-shadow:none;
    margin-bottom: 10px;
}