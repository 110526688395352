.notification {
    width: 100%;
    padding: 5px;
    text-overflow: ellipsis;
}

.notification-warning {
    background-color: #ffa500;
    color: #000;
}

.notification-error {
    background-color: #cf0202;
    color: #fff;
}

.notification-error a {
    color: #fff;
    text-decoration: underline;
}

.notification-information {
    background-color: #00ccff;
    color: #000;
}

.notification-information a, .notification-warning a {
    color: #000;
    text-decoration: underline;
}

