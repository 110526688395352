.example {
    margin-top:10px;
    font-weight: bold;
}

.field-container {
    background-color: #fff;
}

.control-item {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 5px;
    border: 1px solid #000;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;    
}

.control-item.disabled {
    opacity: .5;
    cursor: not-allowed;
}
