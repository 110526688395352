.summary {
    border: 1px solid #cbcbcb;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 10px;
}

.summary-overall-value {
    font-size: 35px;
    font-weight: bold;
}

.summary table {
    width: 100%;
}