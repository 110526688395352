.input-button-container {
    position: relative;
}

.button-container {
    position: absolute;
    z-index: 20;
    top: 5px;
    right: 10px;
    max-width: 250px;
}

.input-button-container .input {
    padding: 20px;
    padding-right: 250px;
}