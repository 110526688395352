.placeholder {
    height: 40px;
    position: relative;
    margin-top:70px;
    
}

.placeholder .placeholder-overlay {
    background-color: rgb(232, 231, 231);
    opacity: .5;
    position:absolute;
    width: 100%;
    height: 77px;
    padding: 10px 10px 30px 10px;
    border-radius: 10px;
}

.placeholder .placeholder-loader {
    position: absolute;
    width: 100%;
}

.placeholder.s-1, .placeholder.s-1 .placeholder-overlay { 
    height: 115px;
}


.placeholder.s-2, .placeholder.s-2 .placeholder-overlay { 
    height: 152px;
}

.placeholder.s-3, .placeholder.s-3 .placeholder-overlay { 
    height: 263px;
}

.placeholder.s-4, .placeholder.s-4 .placeholder-overlay { 
    height: 350px;
}

.placeholder-content {
    position: absolute;
    width: 100%;
}