.input {
    width: 100%;
    margin: auto;
    display: block;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: inherit;
    font-size: 1.1em;
    background-color: rgba(96, 85, 113, .15);
    border: solid black 1px;
    padding: 5px;
    height:35px;
}

.input:focus {
    background-color: rgba(177, 240, 200, .25);
    border: solid rgba(177, 240, 200, .75) 1px;
}

label {
    font-weight: bold;
}

.input.textarea {
    height: 150px;
}

.input.search {
    height: 37px;
}