.toggle-option-1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.toggle-option-2 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.toggle-option {
    background-color: #4e4e4e;
    color: #fff;
    border: 1px solid #000;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1.1em;
    position: relative;
    z-index: 10;
}

.toggle-option.selected {
    background-color: var(--create-color);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 10px;
    z-index: 11;
}

.toggle-option-1.selected { 
    margin-right: -5px;
}

.toggle-option-2.selected {
    margin-left: -5px;
}