.floating-container {
    position:absolute;
    z-index: 1000;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: #4e4e4e 2px 2px 2px;
    width: 100%;
}

.results-container {
    max-width: 700px;
    position: relative;
}

.selected-result {
    max-width: 700px;
}