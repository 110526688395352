.checkbox {
    box-sizing: border-box;
    border-radius: 5px;
    font-family: inherit;
    font-size: 1.1rem;
    background-color: rgba(96, 85, 113, .15);
    border: solid #000 1px;
    width: 15px;
    height: 15px;
}

.checkbox-container {
    display: flex;
    gap: 10px;
    padding: 10px;
    border: 1px solid #969696;
    border-radius: 5px;
    margin-top:15px;
    align-items: center;
}

.checkbox-container.unboxed {
    border: 0;
}

.checkbox-container:hover {
    cursor: pointer;
    background-color: #e4e4e4;
}

.checkbox-container.selected {
    background-color: #ececec;
}

label {
    font-weight: bold;
}

label:hover {
    cursor: pointer;
}