.modal {
  position: fixed;
  /* Stay in place */
  z-index: 40;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 15px;
  border: 1px solid #888;
  border-radius: 5px;
  width: 100%;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


@media screen and (min-width: 1200px) {
  .modal-content.medium {
    width: 60%;
  }

  .modal-content.small {
    width: 35%;
  }
}

@media screen and (min-width: 1700px) {
  .modal-content.medium {
    width: 50%;
  }

  .modal-content.small {
    width: 25%;
  }
}