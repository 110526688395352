:root {
  --create-color: #54086b;
  --selected-color: #f9e6ff;
  --action-color: #050833;
  --warning-color: #D93526;
}
/*updated for higher contrast red was   --warning-color: #ea4335;*/


@keyframes spinning {
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* .reordering {   
  animation: shake .8s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
} */

body {
  margin: 0;
  font-family: Arial, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:rgba(96,85,113,1);
}

h1 { 
  text-align: center;
  color: rgba(5,8,51,1);
  font-size: 2em;
} 

h1.reset {
  margin-top: inherit;
  margin-bottom: inherit;
  text-align: inherit;
}

h2 {
  color: rgba(84,8,107,1);
  font-size: 1.5em;
}

.field-edit-option {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
}

/*none of my anchor tags or react-router-dom Link or NavLink will have underlines*/
a {
  text-decoration: none;
}

caption {
  display: block;
  /* font-size: 1.5em; */
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  margin-bottom: -1px;
}


/*working on content skips for accessibility*/

a.skip-main {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
}
a.skip-main:focus, a.skip-main:active {
  color: #fff;
  background-color:#050833;
  left: auto;
  top: auto;
  width: 20%;
  height: auto;
  overflow:auto;
  margin: 10px 35%;
  padding:5px;
  border-radius: 5px;
  border:2px solid white;
  text-align:center;
  font-size:1.2em;
  z-index:999;
}


a.skip-to-form-fields {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
}
a.skip-to-form-fields:focus, a.skip-to-form-fields:active {
  color: #fff;
  background-color:#050833;
  left: auto;
  top: auto;
  width: 20%;
  height: auto;
  overflow:auto;
  margin: 10px 35%;
  padding:5px;
  border-radius: 5px;
  border:2px solid white;
  text-align:center;
  font-size:1.2em;
  z-index:999;
}

.section {
  margin-top: 15px;
  margin-bottom: 50px;
  padding: 10px;
}

.form-builder-container {
  min-height: 200px;
  display: flex;
  justify-content: space-between;
}

.advanced-content {
  border: 1px solid #b2abab;
  padding: 10px;
  border-radius: 10px;
}

.new-field-tray {
  width: 300px;
  padding: 10px;
  border-right: 1px solid #b2abab;
  border-bottom: 1px solid #b2abab;
}

.field-options-tray {
  width: 300px;
  padding: 10px;
  border-left: 1px solid #b2abab;
  border-bottom: 1px solid #b2abab;
}

.new-field-tray .field-option-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap:10px;
}

.field-option-container .field-option {
  width: 100%;
  padding: 10px;
}

main {
  margin-top: 64px;
}

.form-main {
  width: 100%;
}

.form-main .fields-container {
  margin:auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 400px;
  padding-bottom: 100px;
}

.fields-container.empty {
  background-color: #eee;
  border: 1px solid #4e4e4e;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
}

.drop-container.empty {
  background-color: #eee;
  border: 1px solid #4e4e4e;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
}

.drop-container.dragging-over {
  background-color: #f9e6ff;
}

.field {
  width: 100%;
}

.radio-button-group {
  width: 100%;
}

.field-wrapper {
  padding: 10px;
  border: 1px solid #000;
  border-radius: 10px;
}

.field-content {
  width: 400px;
}

.field-control {
  width: 200px;
}
.field-control button {
  margin-left: 10px
}


.spin {
  animation-name: spinning;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  /* linear | ease | ease-in | ease-out | ease-in-out */
  animation-timing-function: linear;
  display: inline-block;
}

.form-title-container {
  display: flex;
  justify-content: flex-start;
  gap:10px;
}

.form-title-buttons {
  align-self: center;
}

.profile-stock-image {
  font-size: 10em;
  background-color: rgb(234, 234, 234);
  border-radius: 100px;
  padding: 10px;
  border:5px solid rgba(84,8,107,1);
  color:#54086b
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  border:1px solid #000;
  background-color: #fff;
}
.card.selected {
background-color: var(--selected-color);
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.card-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height:350px;
}

.card-container.large {
  min-height: 500px;
}

.card-container.small {
  min-height: 200px;
}


.email-link {
  text-decoration: underline;
  color:#54086b
}

.help-file-link {
  text-decoration: underline;
  color:#54086b
}

.help-file-em {
  font-style: italic;
  color: #050833;
}

.success-message {
  color: rgb(5, 133, 5);
}