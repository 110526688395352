.query-filter {
  border: 1px solid #000;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  background-color: #fff;
}

.query-container, .query-group-body {
  margin: auto;
  min-height: 400px;
  padding-bottom: 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.trash-container {
  font-size: 54px;
  text-align: center;
  padding: 10px;
  border: 1px solid #909090;
  background-color: #fff;
  color: #000;
  width: 100px;
  border-radius: 10px;
  margin-top: 10px;
}

.trash-container.dragging-over {
  color: #f73d3d;
  border: 1px solid #f73d3d;
}

.query-filter.logic {
  text-align: center;
  width: 90px;
  color: rgba(84,8,107,1);
  font-weight: bold;
}

.query-filter.grouping {
  width: 120px;
  text-align: center;
  color: rgba(84,8,107,1);
  font-weight: bold;
}

.query-group-body {
  border: 1px solid #000;
  width: 100%;
  border-radius: 10px;
}

.query-group-body.dragging-over { 
  background-color: aqua;
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
}