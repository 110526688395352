.tab-headers {
    display: flex;
    justify-content: start;
    padding-left: 20px;
}

.tab-header {
    padding: 10px;
    font-weight: bold;
    font-size: 1.3em;
    display: flex;
    align-items: center;
    z-index: 10;
}

.tab-header:hover {
    cursor: pointer;
    text-decoration: underline;
}

.tab-header.active {
    padding: 20px;
    padding-right: 20px;
    border-top: 1px solid #cbcbcb;
    border-left: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #fff;
}
.tab-body {
    border: 1px solid #cbcbcb;
    z-index: 9;
    margin-top: -1px;
    background-color: #fff;
    border-radius: 15px;
}

.tab-content.hidden {
    display: none;
}

.tab-content {
    padding: 10px;
}