.radiobutton {
    box-sizing: border-box;
    border-radius: 5px;
    font-family: inherit;
    font-size: 1.1rem;
    background-color: rgba(96, 85, 113, .15);
    border: solid #000 1px;
    width: 15px;
    height: 15px;
}

.radiobutton-container {
    display: flex;
    gap: 10px;
    padding: 10px;
    border: 1px solid #969696;
    border-radius: 5px;
    margin-top:15px;
}

.radiobutton-container.unboxed {
    border: 0;
}

.radiobutton-container:hover {
    cursor: pointer;
    background-color: #e4e4e4;
}

.radiobutton-container.selected {
    background-color: #ececec;
}

label {
    font-weight: bold;
}

label:hover {
    cursor: pointer;
}

.middle {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #969696;
    border-left: 1px solid #969696;
    border-right: 1px solid #969696;
    margin-top: 0;
}

.first {
    border-radius: 0;
    border: 0;
    border: 1px solid #969696;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-top: 0;
}

.last {
    border-radius: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 0;
    border-bottom: 1px solid #969696;
    border-left: 1px solid #969696;
    border-right: 1px solid #969696;
    margin-top: 0;
} 

.first.last {
    border-radius: 5px;
    border: 1px solid #969696;
}