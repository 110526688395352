.report-step {
    border: 1px solid #cbcbcb;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.report-criteria, .report-results {
    border: 1px solid #cbcbcb;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    max-width: 95%;
    margin: auto;
}

.report-results {
    margin-top:20px;
    margin-bottom: 50px;
}

.report-steps {
    max-width: 95%;
    margin: auto;
    overflow: hidden;
}

.report-step-header, .report-criteria-header, .report-results-header {
    padding-left: 5px;
    padding-right: 5px;
}

.report-step-body, .report-criteria-body, .report-results-body {
    -webkit-transition: max-height .5s;
    -moz-transition: max-height .5s;
    -ms-transition: max-height .5s;
    -o-transition: max-height .5s;
    transition: max-height .5s;
    max-height: 0;
    padding-right: 5px;
    padding-left: 5px;
    overflow: hidden;
}

.report-step.expanded .report-step-body, .report-criteria.expanded .report-criteria-body {
    max-height: 20000px;  
}

.report-results.expanded .report-results-body {
    max-height: 100%;
}


