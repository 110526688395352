.side-menu {
    border:0;
}
.side-menu-header {
    padding: 10px;
    background-color: #fff;
    display: flex;
    gap: 20px;
    border: 0;
    align-items: center;
}
.side-menu-title {
    font-size: 1.2em;
    font-weight: bold;
    color: #fff;
    display: none;
}
.side-menu-options {
    display:none;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    min-height: 200px;
    background-color: #efefef;
    display: none;
}
.side-menu-content-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -20px
}
.side-menu-container {
    width: 50px;
    display:block;
    position:absolute;
    z-index: 9;
}
.side-menu-container.showing {
    width: 300px;
    box-shadow: #4e4e4e 2px 2px 2px;
}
.side-menu-container.showing .side-menu-title  {
    display: block;
}
.side-menu-container.showing .side-menu-options {
    display: flex;
}
.side-menu-container.showing .side-menu-header {
    background-color: rgba(84,8,107,1);
    border-bottom: 1px solid #000;
}
.side-menu-container.showing .side-menu {
    border: 1px solid #000;
    border-radius: 5px;
}
.content-container {
    width: 100%;
    margin-top: 50px;
}
.content {
    padding: 20px;
    border: 1px solid #000;
    border-radius: 5px;
}

.main-content  {
    padding: 10px;
}

.end-header-content, .start-header-content {
    width: 100%;
    padding: 20px;
}

.end-header-content {
    border: 1px solid #000;
    border-radius: 5px;
}

@media screen and (min-width: 1024px) {

    .side-menu-container, .side-menu-container.showing {
        position: static;
        z-index: 0;    
        box-shadow: none;
        width: 350px;
    }
    .side-menu-content-container {
        margin-top: 0;
    }
    .content-container {
        margin-top: 0px;
    }
    .side-menu-button {
        display: none;
    }
    .side-menu-options {
        display:flex;
    }
    .side-menu-title { 
        display: block;
    }
    .side-menu-header {
        background-color: rgba(84,8,107,1);
        border-bottom: 1px solid #000;
    }
    .side-menu {
        border: 1px solid #000;
        border-radius: 5px;
    }
}