/* .search-participant-form-h1 {
text-align: center;
margin-top: 5rem;

} */

/* .form-header {
  
} */

.participant-search-form {

  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
 align-items: center; /* center the button and input on x-axis*/
}



.participant-search-select {
  background-color: rgba(96,85,113, .15);
  border:solid black 1px;
  border-radius: 5px;
  font:inherit;
  height: 36px;    /*2rem if i do sizing:border-box on app.css I THINK*/
  padding: 5px;

  -webkit-appearance:none;
  -moz-appearance: none;
  appearance:none;
  margin-left:.5rem;
  font-size:1.1rem;
  width: auto;
  color:rgba(96,85,113, 1)
;}

.participant-search-form label {
  font-weight:bold;
  font-size: 1.1rem;
 margin: 1rem;
}

.participant-search-input {
  border-radius: 5px;
  font-size: 1.1rem;
  margin: 0 1rem;
  min-width: 20rem;
  max-width: 30rem;
  border: 1px solid black;
  background-color: rgba(96,85,113, .15);
  padding: 5px;
  display: inline;
  height: 1.5rem;    /*2rem if app.css  border-box*/
  font-family:inherit;
}



.participant-search-input:focus,
.participant-search-select:focus {
  background-color: rgba(177,240, 200, .50);
  border: solid  rgba(177,240, 200, .75) 1px;
  
}




/* .search-label {
  font-size: 16px;
  display: block;
  margin: 3px;
} */

/* .search-btn {
  display: block;
  width: 15%;
  margin: 3px;
} */

.search-submit-btn {
  background-color: rgba(84,8,107,1);
  border: 1px solid rgba(5,8,51,1);
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-family:inherit;
  margin-right: 1rem;
 /* align-self: center; for flexbox...not needed */
  
}

.search-submit-btn:hover {
  background-color: white;
  color: rgba(84,8,107,1);
 
}



@media(max-width:43rem) {

  .participant-search-form {

    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
   align-items: center; /* center the button and input on x-axis*/
  }

  .participant-search-input, 

  .participant-search-form-label 
   {
    min-width: 20rem;
    margin: 1rem;

  }

  .participant-search-select {
    min-width: 20.75rem;
    margin:1rem;
  }
  
  .search-submit-btn {

    min-width: 20rem;
    max-width: 20rem;
    margin: 1rem;

  }

  
}