.file-upload-container {
    width: 100%;
    border: 1px solid #9f9f9f;
    padding: 10px;
    border-radius: 5px;
}

.file-upload-zone:hover {
    cursor: pointer;
}

.file-upload-container.active { 
    border: 1px solid #39c602;
    background-color: rgba(177, 240, 200, .25);
    border: solid rgba(177, 240, 200, .75) 1px;
}

.files-container {
    padding: 10px;
}

.files-container.scroll {
    max-height: 200px;
    overflow-y: scroll;
}