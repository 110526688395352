.select {
    width: 100%;
    margin: auto;
    display: block;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: inherit;
    font-size: 1.1rem;
    background-color: rgba(96, 85, 113, .15);
    border: solid black 1px;
    padding: 5px;
    height: 35px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select:focus {
    background-color: rgba(177, 240, 200, .25);
    border: solid rgba(177, 240, 200, .75) 1px;
}

label {
    font-weight: bold;
}