.dropdown-container {
    position:relative;
}
.dropdown-contents {
    position: absolute;
    z-index: 10;
    background-color: #fff;
    box-shadow: 3px 3px 3px 3px rgba(5,8,51,.5);
    margin-left: -90px;
}
.dropdown-contents.top-1 {
    top: -35px;
}
.dropdown-contents.top-2 {
    top: -70px;
}
.dropdown-contents.top-3 {
    top: -105px;
}
.dropdown-container.top-4 {
    top: -140px;
}
.dropdown-container.top-5 {
    top: -175px;
}

.dropdown-option {
    padding: 0.5rem 1.5rem;
    display: block;
    text-align: center;
    width: 150px;
}

.dropdown-option.large {
    width: 200px;
}

a.dropdown-option {
    text-decoration: none;
    color: inherit;
}

.dropdown-option:hover {
    background-color: rgb(205, 204, 204);
    cursor: pointer;
}