.margin-0 {
    margin: 0 !important;
}

.margin-start-0 {
    margin-left: 0 !important;
}

.margin-end-0 {
    margin-right: 0 !important;
}

.margin-top-0 {
    margin-top: 0 !important;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.margin-vertical-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.margin-horizontal-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}


.margin-start-1 {
    margin-left: 3px !important;
}

.margin-start-2 {
    margin-left: 6px !important;
}

.margin-start-3 {
    margin-left: 9px !important;
}

.margin-start-4 {
    margin-left: 12px !important;
}

.margin-start-5 {
    margin-left: 15px !important;
}

.margin-start-6 {
    margin-left: 20px !important;
}


.margin-end-1 {
    margin-right: 3px !important;
}

.margin-end-2 {
    margin-right: 6px !important;
}

.margin-end-3 {
    margin-right: 9px !important;
}

.margin-end-4 {
    margin-right: 12px !important;
}

.margin-end-5 {
    margin-right: 15px !important;
}

.margin-end-6 {
    margin-right: 20px !important;
}

.margin-top-1 {
    margin-top: 3px !important;
}

.margin-top-2 {
    margin-top: 6px !important;
}

.margin-top-3 {
    margin-top: 9px !important;
}

.margin-top-4 {
    margin-top: 12px !important;
}

.margin-top-5 {
    margin-top: 15px !important;
}

.margin-top-6 {
    margin-top: 20px !important;
}

.margin-bottom-1 {
    margin-bottom: 3px !important;
}

.margin-bottom-2 {
    margin-bottom: 6px !important;
}

.margin-bottom-3 {
    margin-bottom: 9px !important;
}

.margin-bottom-4 {
    margin-bottom: 12px !important;
}

.margin-bottom-5 {
    margin-bottom: 15px !important;
}

.margin-vertical-1 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}

.margin-vertical-2 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
}

.margin-vertical-3 {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
}

.margin-vertical-4 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}

.margin-vertical-5 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.margin-vertical-6 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}


.margin-horizontal-1 {
    margin-left: 3px !important;
    margin-right: 3px !important;
}

.margin-horizontal-2 {
    margin-left: 6px !important;
    margin-right: 6px !important;
}

.margin-horizontal-3 {
    margin-left: 9px !important;
    margin-right: 9px !important;
}

.margin-horizontal-4 {
    margin-left: 12px !important;
    margin-right: 12px !important;
}

.margin-horizontal-5 {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.margin-horizontal-6 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.margin-1 {
    margin: 3px !important;
}

.margin-2 {
    margin: 6px !important;
}

.margin-3 {
    margin: 9px !important;
}

.margin-4 {
    margin: 12px !important;
}

.margin-5 {
    margin: 15px !important;
}

.margin-6 {
    margin: 20px !important;
}

.padding-start-1 {
    padding-left: 3px !important;
}

.padding-start-2 {
    padding-left: 6px !important;
}

.padding-start-3 {
    padding-left: 9px !important;
}

.padding-start-4 {
    padding-left: 12px !important;
}

.padding-start-5 {
    padding-left: 15px !important;
}

.padding-end-1 {
    padding-right: 3px !important;
}

.padding-end-2 {
    padding-right: 6px !important;
}

.padding-end-3 {
    padding-right: 9px !important;
}

.padding-end-4 {
    padding-right: 12px !important;
}

.padding-end-5 {
    padding-right: 15px !important;
}

.padding-top-1 {
    padding-top: 3px !important;
}

.padding-top-2 {
    padding-top: 6px !important;
}

.padding-top-3 {
    padding-top: 9px !important;
}

.padding-top-4 {
    padding-top: 12px !important;
}

.padding-top-5 {
    padding-top: 15px !important;
}

.padding-bottom-1 {
    padding-bottom: 3px !important;
}

.padding-bottom-2 {
    padding-bottom: 6px !important;
}

.padding-bottom-3 {
    padding-bottom: 9px !important;
}

.padding-bottom-4 {
    padding-bottom: 12px !important;
}

.padding-bottom-5 {
    padding-bottom: 15px !important;
}

.padding-vertical-1 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.padding-vertical-2 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.padding-vertical-3 {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}

.padding-vertical-4 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.padding-vertical-5 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.padding-horizontal-1 {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.padding-horizontal-2 {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.padding-horizontal-3 {
    padding-left: 9px !important;
    padding-right: 9px !important;
}

.padding-horizontal-4 {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.padding-horizontal-5 {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.padding-1 {
    padding: 3px !important;
}

.padding-2 {
    padding: 6px !important;
}

.padding-3 {
    padding: 9px !important;
}

.padding-4 {
    padding: 12px !important;
}

.padding-5 {
    padding: 15px !important;
}

.text-center {
    text-align: center !important;
}

.text-background {
    color: #747373;
    font-size: .8rem;
}

.text-normal {
    font-style: normal !important;
    font-weight: normal !important;
    text-decoration: none !important;
}

.text-bold {
    font-weight: bold !important;
}

.text-italic {
    font-style: italic !important;
}

.text-underline {
    text-decoration: underline !important;
}

.text-sub {
    font-size: .8em;
    color: rgb(102, 102, 102);
    margin-top: 3px;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-danger {
    color: var(--warning-color);
}

.text-large {
    font-size: 3em !important;
}

.text-smaller {
    font-size: .8em !important;
}

.border {
    border: 1px solid rgb(195, 193, 193) !important;
}

.border-bottom {
    border-bottom: 1px solid rgb(195, 193, 193) !important;
}


.border-top {
    border-top: 1px solid rgb(195, 193, 193) !important;
}
.border-start {
    border-left: 1px solid rgb(195, 193, 193) !important;
}
.border-end {
    border-right: 1px solid rgb(195, 193, 193) !important;
}
.rounded {
    border-radius: 10px;
}

.max-width-200 {
    max-width: 200px;
    word-break: break-all;
}

.margin-top-n5 {
    margin-top: -15px !important;
}

.sub-table-container {
    padding: 10px;
}

.sub-table-header-row {
    background-color: #e5e5e5;
    color: #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}

.sub-table-row {
    border: 1px solid #000;
}

.expanded-report-row {
    background-color: #e5e5e5;
    border: 1px solid #000;
}

.table {
    border-collapse: collapse;
    width: 100%;
    margin: auto;
    position: relative;
}

.table td {
    /* border-bottom: solid rgba(5,8,51,1) 1px; */
    padding: 2px;
    text-align: left;
    vertical-align: middle;
}

.table thead th, .repeating-header td {
    padding: 5px;
    text-align: left;
    vertical-align: middle;
    border-bottom: solid rgba(5, 8, 51, 1) 1px;
    font-weight: bold;
    
}

.fw-100 {
    width: 100px !important;
}

.fw-200 {
    width: 200px !important;
}

.fw-300 {
    width: 300px !important;
}

.fw-400 {
    width: 400px !important;
}


.table-row {
    border-bottom: solid rgba(5, 8, 51, 1) 1px;
}

.table-row td {
    padding: 10px;
}



.wrap-text {
    overflow-wrap: break-word;
    word-break: break-all;
}

.inline-size-1 {
    inline-size: 100px !important;
}

.inline-size-2 {
    inline-size: 150px !important;
}
.inline-size-3 {
    inline-size: 200px !important;
}

.inline-size-4 {
    inline-size: 250px !important;
}
.inline-size-5 {
    inline-size: 300px !important;
}
.inline-size-6 {
    inline-size: 350px !important;
}

.highlight {
    background-color: #eefeff !important;
}

/*Ryan Removed 3/31/2023 for accessibility reasons. not helpful when the screen is black and white.
/* .table-row.even-row {
    background-color: rgba(84,8,107,.15);
} */

.table-details-row {
    border: solid rgba(5, 8, 51, 1) 1px;
}

.table-row:not(.sub-table-header-row):hover {
    background-color: rgba(177, 240, 200, .81);
    /*bumped up to a=.81 to meet contrast requirment with text -
     not great, consider changing text color to something darker or this hover color*/
}


.list-item-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.list-item {
    padding: 20px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #fff;
}

.list-item.sub-list-item {
    width: 98%;
    margin: auto;
    background-color: #efefef;
    border-radius: 0;
}

.list-item:focus{
    border: solid var(--warning-color) 2px;               
    outline: 2px solid var(--warning-color);
    outline-offset: 3px; 
  } 

.list-item-container.reordering .list-item {
    border: 2px dashed #000;
    margin-top:5px;
}

.list-item.skinny {
    padding: 5px;
}

.selection-container {
    padding: 10px;
    background-color: rgb(203, 203, 203);
    border: 1px solid rgb(137, 137, 137);
    border-radius: 5px;
    min-height: 400px;
}

.list-item.selectable {
    background-color: #fff;
}

.sticky {
    position: sticky;
    align-self: flex-start;
}

.sticky.top-100 {
    top: 100px;
}

.sticky.top-75 {
    top: 75px;
}

