
.button {
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border: 1px solid rgba(5,8,51,1);
  background-color: var(--create-color);
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  display:inline-block;
}

/*replaces button.outline*/
.button.secondary {
  background-color: transparent;
  color: var(--create-color);
  border-radius: 5px;
  border: 2px solid var(--create-color);
}

/*tertiary buttons for confirmation windows - these are borderless buttons */
.button.tertiary {
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  color: var(--create-color);
}

/*logout, my profile*/
.button.inverse {
  background-color: transparent;
  border: 1px solid white;
}

.button.flat {
  background-color: transparent;
  border: 0;
  color: var(--create-color);
}

.button.sub-menu {
  background-color: transparent;
  border: 0;
  color: var(--create-color);
}

.button:disabled {
  opacity: .6;
}

.button.block {
  width: 100%;
}

.button.search {
  height: 37px;
}

.button:hover:not(:disabled) {
  background-color: #fff;
  color: var(--create-color);
}

/*ryan 3/31/2023 - focused button now obvious*/
.button:focus:not(:disabled){
  border: solid var(--warning-color) 2px;
  border-radius: 5px;                                
  outline: 2px solid var(--create-color);
  outline-offset: 3px; 
} 

.button.secondary:hover:not(:disabled) {
  background-color: var(--create-color);
  color: #fff;
}


.button.tertiary:hover:not(:disabled) {
  box-shadow: 5px 5px 10px 2px rgba(88, 88, 88, 0.5);
  border: solid var(--create-color) 1px;
  border-radius: 5px; 
  margin:-1px;
}

.button.flat:hover:not(:disabled) {
  border: solid var(--create-color) 1px;
  border-radius: 5px;
  margin: -1px;
  
}

.button.inverse:focus:not(:disabled) {
  outline: 2px solid white;
  outline-offset: 3px; 
}


.button.flat:hover:not(:disabled) {
  background-color: transparent;
  color: #000;
}

.button.sub-menu:hover:not(:disabled) {
  background-color: transparent;
  color: #000;
}

.button.sub-menu.showing {
  background-color: var(--create-color);
  border: 0;
  color: #fff;
}

.button.sub-menu.showing:hover:not(:disabled) {
  background-color: #fff;
  color: var(--create-color);
}

.button.action {
  background-color: var(--action-color);
}

.button.action:hover:not(:disabled) {
  color: var(--action-color);
}

.button.warning {
  background-color: var(--warning-color);
}

.button.warning:hover:not(:disabled) {
  color: var(--warning-color);
}

.button.block {
  width: 100%;
}

.button.small {
  padding: 5px;
}

.button-contents {
  display: flex;
  width: 100%;
  justify-content: center;
}

.button .spin {
  margin-left:5px;
  font-size: 15px;
  align-self: center;
}

.button .button-text {
  align-self: center;
}
